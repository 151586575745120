export enum Icons {
  Ai = 'ai',
  CallCentre = 'call_centre',
  Error = 'error',
  Globe = 'globe',
  IDocumentsAccepted = 'i_documents_accepted',
  MedicalRecords = 'medical_records',
  Microscope = 'microscope',
  Pill = 'pill',
  UiZoom = 'ui_zoom',
  UploadHealth = 'upload_health',
  MedicalSearch = 'medical_search',
  Hourglass = 'hourglass',
  MicrosoftLogo = 'microsoft_logo',
}
