export enum IconType {
  Primary = 'primary',
  Accent = 'accent',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Neutral = 'neutral',
  Light = 'light',
  Black = 'primary-950',
}
