import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconSize } from './icon-size.enum';
import { IconType } from './icon-type.enum';
import { Icons } from './icon.enum';
import { IconService } from './icon.service';

@Component({
  selector: 'myt-icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatIconModule],
})
export class IconsComponent {
  private readonly iconService = inject(IconService);
  iconName: string;
  iconSizeClass = '';
  iconTypeClass = '';
  iconType: IconType = IconType.Primary;

  readonly isCustom = signal(false);
  /**
   * Use Icon type for custom icons and string for material symbols https://fonts.google.com/icons?icon.set=Material+Symbols
   */
  @Input() set name(name: Icons | string) {
    this.iconName = name;
    this.isCustom.set(Object.values(Icons).includes(name as Icons));
  }

  @Input() set type(type: IconType) {
    if (type) {
      this.iconType = type;
      this.iconTypeClass = `text-${type}`;
    }
  }

  @Input() set size(size: IconSize | undefined) {
    if (size) {
      this.iconSizeClass = `myt-icon--${size}`;
    }
  }
}
