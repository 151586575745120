export enum ButtonType {
  Flat = 'flat',
  FlatLink = 'flat-link',
  Stroked = 'stroked',
  StrokedWhite = 'stroked-white',
  StrokedError = 'stroked-error',
  Basic = 'basic',
  Icon = 'icon',
  FabMiniIcon = 'fab-icon',
  Link = 'link',
}
