import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerColorTypes } from './spinner.type';

@Component({
  selector: 'myt-spinner',
  standalone: true,
  imports: [MatProgressSpinnerModule, NgClass],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  readonly strokeWidth = input<number>(3);
  readonly diameter = input<number>(32);
  readonly color = input<SpinnerColorTypes>();
  readonly overlay = input<boolean>(false);
  readonly spinnerText = input<string>('');
}
