import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, booleanAttribute, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Params, RouterLink } from '@angular/router';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ButtonNativeType } from './button-native-type.enum';
import { ButtonSize } from './button-size.enum';
import { ButtonType } from './button-type.enum';

@Component({
  selector: 'myt-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, MatButtonModule, SpinnerComponent, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsComponent {
  @Input() type: ButtonType = ButtonType.Basic;
  @Input() buttonNativeType: ButtonNativeType = ButtonNativeType.Button;
  @Input({ transform: booleanAttribute }) disabled = false;
  /**
   * In case you need to use icon inside the button, use ng-content
   */
  @Input() text?: string;
  @Input() btnColor?: string;
  @Input() disableShadow = false;
  @Input() customCssClasses = '';
  readonly linkTarget = input<'_blank' | '_self'>('_self');
  readonly routeConfig = input<{
    link: string;
    queryParams?: Params;
  }>();
  readonly loading = input(false);
  @Input() set size(size: ButtonSize) {
    if (size) {
      this.buttonSizeClass = `myt-button--${size}`;
    }
  }
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  buttonType = ButtonType;
  buttonSizeClass = '';
}
