import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { Icons } from './icon.enum';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private readonly path: string = '/assets/icons';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.registerIcons();
  }

  registerIcons(): Observable<void> {
    Object.values(Icons).forEach((icon: Icons) => {
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.path}/${icon}.svg`));
    });

    return of();
  }
}
